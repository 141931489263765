import { FC, useEffect } from 'react'
import { Flex } from '@/components/atoms/Grid'
import { Capitalized2, H1 } from '@/components/atoms/typography'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import ToolbarLinkLogo from '@/components/molecules/toolbarLinkLogo'
import { SectionSeparator } from '@/components/atoms/separator'
import { SmallBlackButton } from '@/components/atoms/button'
import { Routes } from '@/core/routes'
import Image from 'next/image'
import { Asset, useAsset } from '@/providers/assetsProvider'
import { validFor } from '@/theme/validFor'
import { useMhub } from '@/providers/mhubProvider'

type ErrorPageProps = {
  code: string
  description: string
  buttonText: string
  redirect?: boolean
}

const ErrorPage: FC<ErrorPageProps> = ({
  code,
  description,
  buttonText,
  redirect = false
}) => {
  const { t } = useTranslation('common')
  const { asset } = useAsset()
  const { pushErrorPage } = useMhub()

  useEffect(() => {
    pushErrorPage(code, description)

    if (redirect) {
      const timer = setTimeout(() => {
        window.location.replace(Routes.home)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [])

  return (
    <Content>
      <BackgroundWrapper>
        <Image
          src={asset(Asset.BGNotFound, true)}
          layout="fill"
          objectFit="cover"
          objectPosition="left top"
          unoptimized
        />
      </BackgroundWrapper>
      <Flex flexDirection="column" alignItems="center" width="100%">
        <ToolbarLinkLogo consistentAcrossLayouts={true} my={40} />
        <SectionSeparator />
        <StyledTitle p={0} mt={180}>
          {t('ErrorPages.title')}
        </StyledTitle>
        <StyledH1>{code}</StyledH1>
        <StyledDescription p={0} m={0}>
          {description}
        </StyledDescription>
        <StyledButton
          onClick={() => {
            window.location.replace(Routes.home)
          }}
          title={buttonText}
        />
      </Flex>
    </Content>
  )
}

export default ErrorPage

const Content = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 180px;
`

const StyledTitle = styled(Capitalized2)`
  color: ${({ theme }) => theme.colors.onBackground};
  opacity: 60%;
  padding: 0;
`

const StyledDescription = styled(Capitalized2)`
  color: ${({ theme }) => theme.colors.onBackground};
  padding: 0;
  margin: 0 0 10px;
  max-width: 280px;
  text-align: center;
`

const StyledH1 = styled(H1)`
  font-size: 200px;
  color: ${({ theme }) => theme.colors.accent};
  margin: 0 0 20px;
`

const StyledButton = styled(SmallBlackButton)`
  margin-top: 10px;
  padding: 12px 25px;
  ${validFor.mobile`
    margin-left: 16px;
    margin-right: 16px;
    width: 90%;
  `}
`

const BackgroundWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  left: 25%;
  height: 100%;
  justify-items: flex-start;
  align-items: flex-start;
  overflow: no-content;
  z-index: -1;
  ${validFor.mobile`
    left: 0;
  `}
`
