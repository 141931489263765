import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import ErrorPage from '@/components/organisms/errorPage'

const FactCool404: FC = () => {
  const { t } = useTranslation('common')
  return (
    <ErrorPage
      code={t('ErrorPages.NotFound.errCode')}
      description={t('ErrorPages.NotFound.description')}
      buttonText={t('ErrorPages.NotFound.button')}
      redirect
    />
  )
}

export default FactCool404
